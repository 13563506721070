import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { validateDropdown } from "../../../utilities/Validator";
import Iicon from "../../patients/doctor-score-form/Iicon.png";
const Dropdown = forwardRef(
  (
    {
      value,
      label,
      data,
      placeholder,
      styleClass,
      validators,
      onChange,
      disabled,
      className,
      view_tool_tip,
    },
    ref
  ) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
      const { value } = event.target;
      setError(validateDropdown(validators, value));
      onChange(value);
      // if (value == "") {

      // }
    };

    useImperativeHandle(ref, () => ({
      errUpdate(e) {
        setError(validateDropdown(validators, ""));
        console.log("err", validators, value);
      },
    }));

    return (
      <div className={`form-group ${styleClass}`}>
        {label && <label htmlFor="app-input-field">{label}</label>}
        {view_tool_tip && (
           <p className="msg_txt_doc_share">
          Please choose the organization from the list below where you would like to enable sharing.
                       </p>
        )}

        <select
          value={value}
          className={`form-control form-select ${className} data_export_marg`}
          onChange={handleChange}
          disabled={disabled}
          readOnly={disabled}
        >
          <option value="">{placeholder}</option>
          {data?.map((item, key) => (
            <option key={key} value={item?.value}>
              {item?.label}
            </option>
          ))}
        </select>
        {error && <span className="text-danger">{error.message}</span>}
      </div>
    );
  }
);

Dropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array.isRequired,
  validators: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  validators: [],
  styleClass: "",
  disabled: false,
  className: "",
};

export default Dropdown;
